export const GTM_WIDEO = "wideodermatoskopia";
export const GTM_CHIRURGIA = "chirurgia";
export const GTM_NIECHIRURGIA = "niechirurgiczne";
export const GTM_ONKOLOGIA = "konsultacje_onkologiczne";
export const GTM_DERMATOLOGIA = "konsultacje_dermatologiczne";
export const GTM_E_ONKOLOGIA = "konsultacje_e_onkologiczne";
export const GTM_LEAD_UMOW_SIE = "lead_umow_sie";
export const GTM_LEAD_UMOW_SIE_ONLINE = "lead_umow_sie_online";
export const GTM_INIT_CHECKOUT_UMOW_SIE = "open_online_registration_umow_sie";
export const GTM_PHONE_FOOTER_STICKY_MOBILE = "phone_number_sticky_footer_mobile";
export const GTM_PHONE_FOOTER = "phone_number_footer";
export const GTM_PHONE_NAVIGATION_TOP_BAR = "phone_num_navbar";
export const GTM_PHONE_LANDING_PAGE_CONTACT_SECTION = "phone_number_landing_page_contact_section";
export const GTM_PHONE_CONTACT_PAGE = "phone_number_contact_page";
export const GTM_LEAD_GIFT_MAINPAGE = "lead_gift_mainpage";
export const GTM_LEAD_GIFT_PRICELIST = "lead_gift_pricelist";
export const GTM_LEAD_GIFT_NAVBAR = "lead_gift_navbar";
export const GTM_GIFT_BUY_NOW_CLICK = "gift_buy_now";