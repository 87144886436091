import React, { useState } from "react";
import { Link } from "gatsby";
import logo from "../img/logo.svg";
import hamburgerIcon from "../img/hamburger_menu.svg";
import CloseIcon from "../img/close.svg";
import GiftCardIcon from "../img/giftCard.svg";
import ChevronIcon from "../img/chevron-up.svg";
import { Helmet } from "react-helmet";
import { LOCATIONS, CURRENT } from "../config/cities";
import "../styles/navigation.scss";
import CallButton from "./CallButton";
import Button from "./Button";
import {
  GTM_LEAD_GIFT_NAVBAR,
  GTM_LEAD_UMOW_SIE,
  GTM_PHONE_NAVIGATION_TOP_BAR,
} from "../config/gtm_classes";
import { IS_GIFT_CARD_GLOBAL } from "../config/featureFlag";

const NavigationItemWithSubmenu = ({
  label = "",
  children = null,
  highlightItem = false,
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return (
    <div
      className={`navigation-item dropdown
      ${isMenuOpened ? "dropdown--opened" : ""}
      ${highlightItem ? "choose-city" : ""}`}
    >
      <div className="heading" onClick={() => setIsMenuOpened(!isMenuOpened)}>
        <span>{label}</span>
        <img
          className={isMenuOpened ? "" : "flip"}
          src={ChevronIcon}
          alt={isMenuOpened ? "Zwiń" : "Rozwiń"}
          title={isMenuOpened ? "Zwiń" : "Rozwiń"}
        />
      </div>
      <div className="submenu">{children}</div>
    </div>
  );
};

const Navigation = ({ constactCtaComponent = null }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const landingPages = [
    "dermatoskopowe-badanie-znamion-wideodermatoskopia",
    "usuwanie-znamion-pieprzykow",
    "laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek",
    "konsultacje-onkologiczne-leczenie-czerniaka",
  ];

  const isOnLandingPage = landingPages.some(
    (page) => typeof window !== "undefined" && window.location.pathname.includes(page)
  );

  return (
    <nav
      className="navigation sticky"
      role="navigation"
      aria-label="main-navigation"
    >
      <Helmet
        bodyAttributes={{
          "navigation-state": isMenuOpened ? "opened" : "",
        }}
      />
      <div className="logo">
        <Link to="/" title="Logo">
          <img src={logo} alt="Twoje Znamiona" />
        </Link>
      </div>
      <div
        className={`navigation-items ${
          isMenuOpened ? "navigation-items--opened" : ""
        }`}
      >
        <NavigationItemWithSubmenu
          label="Wybierz miasto"
          highlightItem={!isOnLandingPage}
        >
          {LOCATIONS.filter(
            (location) => location.link && location.type === CURRENT
          ).map((location) => (
            <Link
              key={location.city}
              className="navigation-item"
              to={`/${location.link}`}
              activeClassName="active"
              partiallyActive={true}
            >
              {location.city}
            </Link>
          ))}
        </NavigationItemWithSubmenu>
        <Link className="navigation-item" to="/cennik" activeClassName="active">
          <div className="heading">Cennik</div>
        </Link>
        <NavigationItemWithSubmenu label="Diagnostyka Znamion">
          <Link
            className="navigation-item"
            to="/wideodermatoskopia-komputerowe-badanie-znamion"
            activeClassName="active"
          >
            Wideodermatoskopia
          </Link>
          <Link
            className="navigation-item"
            to="/dermatoskopia-badanie-znamion"
            activeClassName="active"
          >
            Badanie Znamion
          </Link>
          <Link
            className="navigation-item"
            to="/laserowe-usuwanie-znamion"
            activeClassName="active"
          >
            Laserowe Usuwanie Znamion
          </Link>
          <Link
            className="navigation-item"
            to="/chirurgiczne-usuwanie-znamion"
            activeClassName="active"
          >
            Chirurgiczne Usuwanie Znamion
          </Link>
        </NavigationItemWithSubmenu>

        <Link
          className="navigation-item"
          to="/czerniak"
          activeClassName="active"
        >
          <div className="heading">Czerniak</div>
        </Link>

        {/* <NavigationItemWithSubmenu label="Nowotwory skóry">
          <Link className="navigation-item" to="/czerniak" activeClassName="active" >
            Czerniak
          </Link>
          <Link className="navigation-item" to="/dermatoskopia-badanie-znamion" activeClassName="active" >
            Raki skóry
          </Link>
        </NavigationItemWithSubmenu> */}

        <Link className="navigation-item" to="/o-nas" activeClassName="active">
          <div className="heading">O nas</div>
        </Link>

        <Link className="navigation-item" to="/blog" activeClassName="active">
          <div className="heading">Blog</div>
        </Link>

        {IS_GIFT_CARD_GLOBAL && (
          <Link
            className={`navigation-item gift-card ${GTM_LEAD_GIFT_NAVBAR}`}
            to="/bon-podarunkowy"
            activeClassName="active"
          >
            <img
              className="giftCardIcon"
              src={GiftCardIcon}
              alt="Bon podarunkowy"
            />
            <div className="heading">Kup Bon</div>
          </Link>
        )}
      </div>
      <div className="navigation-contact">
        <a
          href="tel:+48781445685"
          title="+48781445685"
          className={`phone-wrapper ${GTM_PHONE_NAVIGATION_TOP_BAR}`}
        >
          <CallButton className="normal-icon" />
          <span className="phone-number">+48 781 445 685</span>
        </a>

        <Button size="M" className="normal-button">
          {constactCtaComponent || (
            <Link to="/kontakt" className={GTM_LEAD_UMOW_SIE}>
              Umów się
            </Link>
          )}
        </Button>
        <Button size="S" className="small-button">
          {constactCtaComponent || (
            <Link to="/kontakt" className={GTM_LEAD_UMOW_SIE}>
              Umów się
            </Link>
          )}
        </Button>
      </div>
      <div
        className="navigation-items-mobile"
        onClick={() => setIsMenuOpened(!isMenuOpened)}
      >
        {!isMenuOpened && (
          <img className="hamburgerIcon" src={hamburgerIcon} alt="Menu" />
        )}
        {isMenuOpened && (
          <img className="closeIcon" src={CloseIcon} alt="Zamknij Menu" />
        )}
      </div>
    </nav>
  );
};

export default Navigation;
